







































import vue from 'vue';
import AverageChart from '@/components/Charts/AverageChart.vue';
import AbbiApi from '@/apis/abbi.api';
import ScriptModel from '@/models/script.model';
import StatModel from '@/models/stat.model';
import PieChart from '@/components/Charts/PieChart.vue';
import BarChart from '@/components/Charts/BarChart.vue';
import NpsChart from '@/components/Charts/NpsChart.vue';
import Chart from 'chart.js';
import settings from '@/settings';

// https://vue-chartjs.org/guide/#introduction
export default vue.extend({
    name: 'Stats',
    components: {
        AverageChart, PieChart, BarChart, NpsChart,
    },
    data() {
        return {
            script: null as null | ScriptModel,
            stats: null as null | StatModel[],
            activeIdx: 0,
            questionsOnly: true as boolean,
            style: {} as Record<string, any>,
            chartX: 288,
            chartY: 335,

            stylesLoaded: false,
        };
    },
    computed: {
        chartType(): string {
            return this.currentParamContainer.options.charttype;
        },
        logo(): string {
            return this.style.community?.logo || '';
        },
        currentInstruction() {
            if (this.script === null) return null;
            // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
            // @ts-ignore TS2339
            return this.script.instructions[this.activeIdx];
        },
        currentParamContainer() {
            if (this.script === undefined) return undefined;
            // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
            // @ts-ignore TS2339
            return this.currentInstruction!.question || this.currentInstruction!.action;
        },
        currentStat(): StatModel | null {
            // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
            // @ts-ignore TS2339
            if (this.stats === null || this.stats.length === 0) return undefined;
            return this.stats[this.currentInstruction!.uuid] as StatModel || undefined;
        },
        currentPopulation() {
            // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
            // @ts-ignore TS2339
            const current = this.currentStat;
            if (current === undefined) return 0;
            return current.selected;
        },
        currentSample() {
            // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
            // @ts-ignore TS2339
            const current = this.currentStat;
            if (current === undefined) return 0;
            return current.answered;
        },
        barChartHeight() {
            if (this.chartType !== 'barchart' || !this.currentParamContainer.choices) {
                return null;
            }
            // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
            // @ts-ignore TS2339
            const choicesCount = this.currentParamContainer.choices.length;
            let barCHartHeight = 105 * choicesCount;

            // eslint-disable-next-line no-restricted-globals
            if (screen.width <= 760) {
                barCHartHeight = 80 * choicesCount;
                if (choicesCount <= 8) {
                    barCHartHeight = 531;
                }
            } else if (choicesCount <= 8) {
                barCHartHeight = 600;
            }
            return {
                height: `${barCHartHeight}px`,
            };
        },
        next(): number {
            if (this.script === null) return 0;
            if (!this.questionsOnly) return Math.min(this.script.instructions.length - 1, this.activeIdx + 1);
            let steps = 1;
            while (this.activeIdx + steps < this.script.instructions.length) {
                if (this.script.instructions[this.activeIdx + steps].type === 'question') {
                    return this.activeIdx + steps;
                }
                steps++;
            }
            return this.activeIdx;
        },
        last(): number {
            if (this.script === null) return 0;
            if (!this.questionsOnly) return Math.max(0, this.activeIdx - 1);
            let steps = 1;
            while (this.activeIdx - steps >= 0) {
                if (this.script.instructions[this.activeIdx - steps].type === 'question') {
                    return this.activeIdx - steps;
                }
                steps++;
            }
            return this.activeIdx;
        },
    },
    created() {
        window.addEventListener('keyup', this.test);
    },
    methods: {
        leftClick() {
            this.activeIdx -= 1;
            const statsPage = document.getElementById('stats-page');
            // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
            // @ts-ignore
            statsPage.scrollTop = 0;
            window.scrollTo(0, 0);
        },
        rightClick() {
            this.activeIdx += 1;
            const statsPage = document.getElementById('stats-page');
            // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
            // @ts-ignore
            statsPage.scrollTop = 0;
            window.scrollTo(0, 0);
        },
        getDependencies() {
            // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
            // @ts-ignore
            Chart.defaults.global.plugins.datalabels.display = false;
            const community = this.$route.params.community as string;
            const script = this.$route.params.script as string;
            AbbiApi.getStyle(community)
                .then((data) => {
                    this.style = data;
                    Chart.defaults.global.defaultFontFamily = '\'Quicksand\'';
                    if (data.community !== undefined && data.community['font-fam'] !== undefined) {
                        const fontFam = data.community['font-fam'].replaceAll('font-family:', '')
                            .replaceAll(';', '')
                            .trim();
                        let fontFamily = `${fontFam}`;
                        Chart.defaults.global.defaultFontFamily = fontFamily;
                        if (data.community['font-url'] !== undefined) {
                            const environment = process.env.VUE_APP_ABBI_ENVIRONMENT || 'local';
                            // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
                            // @ts-ignore
                            if (data.community['font-url'].lastIndexOf(settings.objectStorage[environment]) >= 0) {
                                // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
                                // @ts-ignore TS2304
                                const font = new FontFace(fontFam, `url(${data.community['font-url']})`);
                                font.load()
                                    .then((loadedFont: any) => {
                                        (document as any).fonts.add(loadedFont);
                                    });
                                fontFamily = `'${fontFam}'`;
                            } else {
                                const documentHead = document.head;
                                const link = document.createElement('link');
                                link.type = 'text/css';
                                link.rel = 'stylesheet';
                                link.href = data.community['font-url'];
                                documentHead.appendChild(link);
                            }
                        }
                        document.documentElement.style.setProperty('--community-font-fam', fontFamily);
                    }

                    const $e = new Event('styleload');
                    document.dispatchEvent($e);
                    this.stylesLoaded = true;

                    if (data.stats === undefined) {
                        console.warn('no chatstyle has been made yet.');
                    }

                    if (!data.stats) return;
                    Chart.defaults.global.defaultFontColor = this.style.stats.body_color || 'black';
                    Object.entries(data.stats)
                        .forEach((item) => {
                            const identifier = item[0].replaceAll('_', '-');
                            const value = String(item[1]);

                            if (identifier.indexOf('image') !== -1) {
                                document.documentElement.style.setProperty(`--${item[0].replaceAll('_', '-')}`, `url(${item[1]})`);
                            } else if (/^[1-9]+[0-9]*$/.test(value)) {
                                document.documentElement.style.setProperty(`--${item[0].replaceAll('_', '-')}`, `${item[1]}px`);
                            } else {
                                document.documentElement.style.setProperty(`--${item[0].replaceAll('_', '-')}`, item[1] as string);
                            }
                        });
                });

            this.getScriptOutline(community, script);
        },
        getScriptOutline(community: string, script: string) {
            AbbiApi.GetScriptOutline(community, script)
                .then((responseData) => {
                    this.script = responseData as ScriptModel;

                    document.title = `${this.script.name} Livestats`;

                    this.getStats();

                    if (this.questionsOnly && this.currentParamContainer === undefined) {
                        this.activeIdx = this.next;
                    }
                });
        },
        getStats() {
            if (document.hidden) {
                window.setTimeout(this.getStats, 1000);
                return;
            }

            const community = this.$route.params.community as string;
            const script = this.$route.params.script as string;
            AbbiApi.GetStatsForScript(community, script)
                .then((responseData) => {
                    this.stats = responseData as StatModel[];
                    window.setTimeout(this.getStats, 2000);
                });
        },
        test(event: KeyboardEvent) {
            if (event.key === 'ArrowRight') {
                this.activeIdx = this.next;
            }

            if (event.key === 'ArrowLeft') {
                this.activeIdx = this.last;
            }
        },
    },
    mounted() {
        this.questionsOnly = false;// this.$route.query.all !== 'true';
        this.getDependencies();
    },
    beforeDestroy() {
        window.removeEventListener('keyup', this.test);
    },
});
